<template>
  <!-- permalink START -->
  <div class="permalink-inner">
    <router-link
    class="f-link"
      :to="permalink.permalink"
    ></router-link>
    <div class="image-holder">
      <img :src="permalink.icon.src" />
    </div>
    <h4 v-html="permalink.headline"></h4>
  </div>
  <!-- permalink END -->
</template>

<script>
export default {
  name: 'permalink',
  props: {
    permalink: {
      type: Object,
    },
  },
};
</script>
